<template>
  <div>
    <div class="privacy-policy mt-5 pt-5">
      <div class="container">
        <div class="row">
          <div class="col-md-10 main-heading mb-5">
            <h3 class="mb-1">Privacy Policy</h3>
            <div class="divider"></div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <!-- Introduction -->
            <div class="mb-4">
              <h5>Last Updated: April 27, 2024</h5>
              <p><strong>BRIKS Technology</strong> ("we," "our," or "us") is committed to protecting your privacy. This Privacy Policy outlines how we collect, use, disclose, and safeguard your personal information when you use our AI-powered app, <strong>BRIKS.AI</strong> ("the App"). Please read this policy carefully to understand our practices regarding your personal data.</p>
            </div>

            <!-- 1. Information We Collect -->
            <div class="mb-4">
              <h5>1. Information We Collect</h5>
              <ul>
                <li><strong>Personal Identification Information:</strong>
                  <ul>
                    <li>Name</li>
                    <li>Email Address</li>
                    <li>Location</li>
                    <li>Password</li>
                  </ul>
                </li>
                <li><strong>Usage Data:</strong>
                  <ul>
                    <li>Information about how you use the App, such as the features you use, the content you create, and your interactions within the App.</li>
                  </ul>
                </li>
              </ul>
            </div>

            <!-- 2. How We Collect Your Information -->
            <div class="mb-4">
              <h5>2. How We Collect Your Information</h5>
              <ul>
                <li><strong>Directly from You:</strong> When you register for an account, create content, or interact with the App.</li>
                <li><strong>Automatically:</strong> Through cookies and similar technologies when you access the App. Refer to our <a href="#cookies-policy">Cookies Policy</a> for more details.</li>
                <li><strong>From Third Parties:</strong> If you choose to link your account with third-party services, we may receive information from those services.</li>
              </ul>
            </div>

            <!-- 3. Use of Your Personal Information -->
            <div class="mb-4">
              <h5>3. Use of Your Personal Information</h5>
              <ul>
                <li><strong>Provide and Improve Services:</strong> Enable you to create memes and images using our AI tools, personalize your experience, and improve the App’s functionality.</li>
                <li><strong>Communication:</strong> Send you updates, promotional materials, and respond to your inquiries.</li>
                <li><strong>Security:</strong> Protect against unauthorized access and ensure the security of our services.</li>
                <li><strong>Compliance:</strong> Fulfill legal obligations and protect our rights.</li>
              </ul>
            </div>

            <!-- 4. Disclosure of Your Personal Information -->
            <div class="mb-4">
              <h5>4. Disclosure of Your Personal Information</h5>
              <ul>
                <li><strong>Service Providers:</strong> Third-party vendors who assist us in operating the App and providing services.</li>
                <li><strong>Legal Requirements:</strong> When required by law, regulation, or legal process.</li>
                <li><strong>Business Transfers:</strong> In the event of a merger, acquisition, or sale of assets, your information may be transferred to the new owner.</li>
                <li><strong>Protecting Rights:</strong> To protect the rights, property, or safety of BRIKS Technology, our users, or others.</li>
                <li>By providing us with personal information, you consent to the terms of this Privacy Policy and the types of disclosure covered by this Policy. Where we disclose your personal information to third parties, we will request that the third party follow this Policy regarding handling your personal information.</li>
              </ul>
            </div>

            <!-- 5. Security of Your Personal Information -->
            <div class="mb-4">
              <h5>5. Security of Your Personal Information</h5>
              <ul>
                <li>We implement appropriate technical and organizational measures to safeguard your personal information against unauthorized access, alteration, disclosure, or destruction. However, no method of transmission over the internet or electronic storage is 100% secure, and we cannot guarantee absolute security.</li>
                <li>The transmission and exchange of information is carried out at your own risk. We cannot guarantee the security of any information that you transmit to us, or receive from us. Although we take measures to safeguard against unauthorized disclosures of information, we cannot assure you that personal information that we collect will not be disclosed in a manner that is inconsistent with this Privacy Policy.</li>
              </ul>
            </div>

            <!-- 6. Your Rights and Choices -->
            <div class="mb-4">
              <h5>6. Your Rights and Choices</h5>
              <ul>
                <li><strong>Access and Correction:</strong> You can access and update your personal information by logging into your account or contacting us at <a href="mailto:hello@briks.ai">hello@briks.ai</a>.</li>
                <li><strong>Data Deletion:</strong> You may request the deletion of your personal information, subject to certain legal obligations.</li>
                <li><strong>Opt-Out:</strong> You can opt-out of receiving promotional communications by following the unsubscribe instructions in our emails.</li>
              </ul>
            </div>

            <!-- 7. Cookies and Tracking Technologies -->
            <div class="mb-4">
              <h5>7. Cookies and Tracking Technologies</h5>
              <ul>
                <li>We use cookies and similar technologies to enhance your experience on the App. For more information, please refer to our <a href="#cookies-policy">Cookies Policy</a>.</li>
              </ul>
            </div>

            <!-- 8. Children's Privacy -->
            <div class="mb-4">
              <h5>8. Children's Privacy</h5>
              <ul>
                <li>Our App is not intended for individuals under the age of 13. We do not knowingly collect personal information from children. If we become aware that a child has provided us with personal information, we will take steps to delete such information.</li>
              </ul>
            </div>

            <!-- 9. Changes to This Privacy Policy -->
            <div class="mb-4">
              <h5>9. Changes to This Privacy Policy</h5>
              <ul>
                <li>We may update this Privacy Policy from time to time. Any changes will be effective immediately upon posting on the App. We encourage you to review this policy periodically to stay informed about our data practices.</li>
              </ul>
            </div>

            <!-- 10. Contact Us -->
            <div class="mb-4">
              <h5>10. Contact Us</h5>
              <p>If you have any questions or concerns about this Privacy Policy, please contact us at:</p>
              <p><strong>BRIKS Technology</strong><br>
                Email: <a href="mailto:hello@briks.ai">hello@briks.ai</a><br>
                </p>
            </div>

            <!-- Cookies Policy Section -->
            <div class="mb-4" id="cookies-policy">
              <h5>Cookies Policy</h5>
              <p>We use cookies to enhance your experience on our website. Cookies are small files stored on your device that help us understand how you use our site, remember your preferences, and provide personalized content. You can manage your cookie preferences through your browser settings.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PrivacyPolicy'
}
</script>

<style scoped>
.privacy-policy {
  font-family: Arial, sans-serif;
  color: #333;
}

.main-heading h3 {
  font-size: 2.5rem;
  text-align: center;
}

.divider {
  width: 100px;
  height: 4px;
  background-color: #007BFF;
  margin: 10px auto;
}

h5 {
  color: #007BFF;
}

ul {
  list-style-type: disc;
  padding-left: 20px;
}

a {
  color: #007BFF;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

@media (max-width: 768px) {
  .main-heading h3 {
    font-size: 2rem;
  }
}
</style>
